import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState) => ({
  fetching: state.points.fetchingLeaderboard,
  users: state.points.leaderboard,
  userId: state.auth.user?.id || null,
  meta: state.points.meta,
})

const mapDispatch = (dispatch: any) => ({
  onMount: () => {},
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
