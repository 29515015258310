import React, { useEffect } from "react"
import { connector, ContainerProps } from "./containers/Leaderboard.container"
import { UserEntity } from "../../entities/UserEntity"
import { LeaderboardEntity } from "../../entities/LeaderboardEntity"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { LeagueTimeout } from "./components/LeagueTimeout/LeagueTimeout"
import { LeagueBadge } from "./components/LeagueBadge/LeagueBadge"
import { LeagueUsersRanking } from "./components/LeagueUsersRanking/LeagueUsersRanking"
import { leaguesThreshold } from "../../constants/league-threshold"

const leagues: number[] = [
  0, 1, 2, 3, 4,
  // "ruby",
  // "saphir",
  // "emerald",
  // "master",
]

export const Wrapper: React.FC<{
  fetching: boolean
  users: LeaderboardEntity[]
  userId: UserEntity["id"] | null
  onMount: () => void
}> = (props) => {
  useEffect(() => {
    props.onMount()
  }, [])

  const actualLeague = props.users[0]?.league || 0

  return (
    <div className="relative max-w-3xl px-2 pb-8 mx-auto">
      <div className="w-full">
        <div className="text-center pt-4">
          <h1 className="font-display text-3xl md:text-3xl leading-tight text-slate-900 font-bold tracking-tighter">
            {/* @ts-ignore */}
            <FormattedMessage id={`leaderboard/league/title/${actualLeague}`} />
          </h1>
          <p className="text-lg text-gay-900">
            {actualLeague === 0 ? (
              <FormattedMessage
                id={`leaderboard/league/promotion/description/bronze`}
                values={{
                  v: <strong>{leaguesThreshold[0]}</strong>,
                }}
              />
            ) : actualLeague === 4 ? (
              <FormattedMessage
                id={`leaderboard/league/promotion/description/top`}
                values={{
                  b: <strong>{leaguesThreshold[actualLeague - 1]}</strong>,
                }}
              />
            ) : (
              <FormattedMessage
                id={`leaderboard/league/promotion/description/all`}
                values={{
                  v: <strong>{leaguesThreshold[actualLeague]}</strong>,
                  b: <strong>{leaguesThreshold[actualLeague - 1]}</strong>,
                }}
              />
            )}
          </p>

          <LeagueTimeout />

          <div className="overflow-x-auto no-scroll-bar">
            <ul className="flex relative py-4 items-center md:justify-center">
              {leagues.map((league, index) => (
                <li
                  key={league}
                  className="mb-2 mr-2 flex-shrink-0 last:mr-0 capitalize"
                >
                  <LeagueBadge league={league} disabled={index > actualLeague}>
                    <FormattedMessage
                      // @ts-ignore
                      id={`leaderboard/league/name/${league}`}
                    />
                  </LeagueBadge>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <LeagueUsersRanking users={props.users} userId={props.userId} />

      {props.fetching && (
        <>
          <div
            className="mx-auto bg-slate-300 w-60 h-60 animate-pulse rounded-xl"
            style={{ animationDuration: "1000ms" }}
          ></div>

          {Array.from({ length: 10 }).map((e, index) => (
            <div
              key={index}
              style={{
                animationDelay: `${(index + 1) * 100}ms`,
                animationDuration: "1000ms",
              }}
              className="w-full h-20 mt-2 bg-slate-300 rounded-full   animate-pulse"
            ></div>
          ))}
        </>
      )}

      {!props.fetching && props.users.length === 0 && !props.userId && (
        <div className="flex items-center justify-center h-full italic text-slate-700 text-center text-sm">
          <FormattedMessage id="leaderboard/league/empty" />
        </div>
      )}
    </div>
  )
}

export const LockedModalContainer: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const Leaderboard = connector(LockedModalContainer)
