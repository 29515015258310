import { PageProps } from "gatsby"
import React from "react"
import { useIntl } from "react-intl"
import { BackgroundShapes } from "../components/BackgroundShapes"
import { DarkMode } from "../components/DarkMode/DarkMode"
import { Drawer } from "../components/Drawer/Drawer"
import { Footer } from "../components/Footer/Footer"
import { GlobalWrapper } from "../components/GlobalWrapper/GlobalWrapper"
import { Leaderboard } from "../components/Leaderboard/Leaderboard"
import { MobileNavbar } from "../components/MobileNavbar/MobileNavbar"
import { Navbar } from "../components/Navbar/Navbar"
import { SelectLanguageModal } from "../components/SelectLanguageModal/SelectLanguageModal"
import { Seo } from "../components/Seo/Seo"

type Props = PageProps<
  null,
  {
    langKey: string
    level: string
    canonicals: Array<{
      lang: string
      url: string
      isDefault: boolean
      label: string
    }>
  }
>

export const LeaderboardPage: React.FC<Props> = (props) => {
  const intl = useIntl()

  return (
    <GlobalWrapper>
      <Seo
        title={intl.formatMessage({ id: "seo.leaderboad.title" })}
        description={intl.formatMessage({ id: "seo.leaderboad.description" })}
        lang={props.pageContext.langKey}
        langUrls={props.pageContext.canonicals}
      />

      <SelectLanguageModal languages={props.pageContext.canonicals} />

      <div className="">
        <div className="relative">
          <BackgroundShapes step={0} absolute />

          <Navbar langKey={props.pageContext.langKey} />

          <Leaderboard />
        </div>

        <Footer lang={props.pageContext.langKey} />
        <Drawer langKey={props.pageContext.langKey} />
      </div>
      <MobileNavbar langKey={props.pageContext.langKey} />
    </GlobalWrapper>
  )
}

export default LeaderboardPage
